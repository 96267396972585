exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-news-tsx": () => import("./../../../src/pages/news.tsx" /* webpackChunkName: "component---src-pages-news-tsx" */),
  "component---src-pages-oney-cv-tsx": () => import("./../../../src/pages/oney_cv.tsx" /* webpackChunkName: "component---src-pages-oney-cv-tsx" */),
  "component---src-pages-research-tsx": () => import("./../../../src/pages/research.tsx" /* webpackChunkName: "component---src-pages-research-tsx" */),
  "component---src-pages-team-tsx": () => import("./../../../src/pages/team.tsx" /* webpackChunkName: "component---src-pages-team-tsx" */),
  "component---src-pages-writing-tsx": () => import("./../../../src/pages/writing.tsx" /* webpackChunkName: "component---src-pages-writing-tsx" */),
  "component---src-templates-member-tsx": () => import("./../../../src/templates/member.tsx" /* webpackChunkName: "component---src-templates-member-tsx" */),
  "component---src-templates-publication-tsx": () => import("./../../../src/templates/publication.tsx" /* webpackChunkName: "component---src-templates-publication-tsx" */)
}

